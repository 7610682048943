var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/forgot-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    _vm.tokenExpired || !_vm.tokenPrevalidated
                      ? _c("div", { staticClass: "p-8" }, [
                          _c(
                            "div",
                            { staticClass: "vx-card__title mb-8 w-full" },
                            [
                              _c(
                                "tp-cardtext",
                                { attrs: { "min-height": 165 } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Sorry, the password reset link has expired. Please request a new password reset email."
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass:
                                    "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6",
                                  attrs: {
                                    type: "border",
                                    to: "/forgottenpassword",
                                  },
                                },
                                [_vm._v("Forgot Password")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.resetSuccessful
                      ? _c("div", { staticClass: "p-8" }, [
                          _c(
                            "div",
                            { staticClass: "vx-card__title mb-8 w-full" },
                            [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Password Has Been Reset"),
                              ]),
                              _c(
                                "tp-cardtext",
                                { attrs: { "min-height": 150 } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Your password has been updated. Please login to your account using your new password"
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass:
                                    "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6",
                                  attrs: { type: "border", to: "/login" },
                                },
                                [_vm._v("Login")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.tokenPrevalidated && !_vm.resetSuccessful
                      ? _c(
                          "div",
                          { staticClass: "p-8" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-8" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Reset Password"),
                              ]),
                              _c("p", [
                                _vm._v("Please enter your new password"),
                              ]),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "password",
                                "data-vv-validate-on": "blur",
                                "label-placeholder": "New password",
                                name: "newPassword",
                              },
                              model: {
                                value: _vm.newPassword,
                                callback: function ($$v) {
                                  _vm.newPassword = $$v
                                },
                                expression: "newPassword",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("newPassword"))),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "w-full mt-6",
                              attrs: {
                                type: "password",
                                "data-vv-validate-on": "blur",
                                "label-placeholder": "Confirm new password",
                                name: "confirmNewPassword",
                              },
                              model: {
                                value: _vm.confirmNewPassword,
                                callback: function ($$v) {
                                  _vm.confirmNewPassword = $$v
                                },
                                expression: "confirmNewPassword",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("confirmNewPassword"))
                              ),
                            ]),
                            _c("div", { staticClass: "w-full mb-6" }),
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6",
                                on: { click: _vm.resetPassword },
                              },
                              [
                                _vm._v(
                                  "Update Password\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }